import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main(): Unit {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.austen.mesaros.pages.HomePage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.AchievementTitleStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.AchievementIconStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.Box1Style)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.Box2Style)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.EducationHeaderStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.EducationDescStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.JobHeaderStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.JobLocationActiveStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.JobDescStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.HomePageStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.HeaderStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.NavStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.ContentStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.AustenTextStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.navIconStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.navTextStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.BodyStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.NormalButtonStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.PlayButtonStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.SelectedButtonStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.IntroColumnAStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.IntroColumnBStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.ProjectsTitleStyle)
        ctx.theme.registerComponentStyle(com.austen.mesaros.pages.SkillsItemStyle)
        ctx.stylesheet.registerKeyframes(com.austen.mesaros.pages.Rainbow)
        ctx.stylesheet.registerKeyframes(com.austen.mesaros.pages.FadeEffect)
        ctx.stylesheet.registerKeyframes(com.austen.mesaros.pages.ButtonFadeEffect)
        com.austen.mesaros.initSilk(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(AppGlobalsLocal provides mapOf("title" to "Austen Mesaros")) {
            com.austen.mesaros.MyApp {
                router.renderActivePage { renderWithDeferred { it() } }
            }
        }
    }
}

package com.austen.mesaros.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toAttrs
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

val SkillsItemStyle by ComponentStyle {
    base {
        Modifier
            .fontSize(FontSize.XLarge)
            .textAlign(TextAlign.Center)
    }

    Breakpoint.LG {
        Modifier
            .fontSize(3.vw)
            .textAlign(TextAlign.Center)
    }
}

@Composable
fun Skills() {
    Column(
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
            .fillMaxSize()
            .color(Colors.White)
            .overflow(Overflow.Auto)
            .padding(10.px)
            .fontSize(1.vw)
            .gap(10.px)
            .animation(FadeEffect.toAnimation(duration = 0.5.s))
    ) {
        Row(
            horizontalArrangement = Arrangement.SpaceEvenly,
            modifier = Modifier
                .fillMaxSize()
                .gap(10.px)
        ) {
            SkillsItem("Kotlin")
            SkillsItem("Java")
            SkillsItem("C#")
        }
        Row(
            horizontalArrangement = Arrangement.SpaceEvenly,
            modifier = Modifier
                .fillMaxSize()
                .gap(10.px)
        ) {
            SkillsItem("Android")
            SkillsItem("JavaFX")
            SkillsItem("Unity")
        }
        Row(
            horizontalArrangement = Arrangement.SpaceEvenly,
            modifier = Modifier
                .fillMaxSize()
                .gap(10.px)
        ) {
            SkillsItem("Android Studio")
            SkillsItem("Eclipse")
            SkillsItem("Visual Studio")
        }
    }
}

@Composable
fun SkillsItem(skillName: String) {
    Box(
        contentAlignment = Alignment.Center,
        modifier = Modifier
            .fillMaxSize()
            .padding(10.px)
            .backgroundColor(rgba(255, 255, 255, 0.1f))
            .borderRadius(7.px)
    ) {
        Div(attrs = SkillsItemStyle.toAttrs()) {
            Text(skillName)
        }
    }
}
package com.austen.mesaros.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaAndroid
import com.varabyte.kobweb.silk.components.icons.fa.FaGooglePlay
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

val ProjectsTitleStyle by ComponentStyle {
    base {
        Modifier
            .color(Colors.White)
            .fontSize(FontSize.XXLarge)
    }

    Breakpoint.LG {
        Modifier
            .color(Colors.White)
            .fontSize(2.vw)
    }
}

@Composable
fun Projects() {

    val breakpoint = rememberBreakpoint()
    var showDetails by remember { mutableStateOf(false) }

    if (/*breakpoint == Breakpoint.LG || breakpoint == Breakpoint.XL*/false) {
        Row (horizontalArrangement = Arrangement.SpaceEvenly,
            verticalAlignment = Alignment.Top,
            modifier = Modifier.fillMaxSize().padding(25.px).gap(25.px)) {

            Box(
                contentAlignment = Alignment.CenterStart,
                modifier = Modifier.fillMaxWidth(50.percent)
                    .fillMaxHeight()
                    .color(Colors.White)
                    .overflow(Overflow.Auto)
                    .gap(10.px)
                    .border(1.px, LineStyle.Solid, Colors.Green)
                    .animation(FadeEffect.toAnimation(duration = 0.5.s))
/*                    .border(1.px, LineStyle.Solid, Colors.Red)*/
            ) {
                Column(
                    verticalArrangement = Arrangement.Top,
                    horizontalAlignment = Alignment.CenterHorizontally,
                    modifier = Modifier.fillMaxSize()
                        .color(Colors.White)
                        .overflow(Overflow.Auto)
                        .gap(10.px)
                        .animation(FadeEffect.toAnimation(duration = 0.5.s))
                        .border(1.px, LineStyle.Solid, Colors.Purple)
                ) {
                    ProjectsItemDesktop(
                        "Leftover Monitor",
                        "https://play.google.com/store/apps/details?id=com.austenmesaros.leftovermonitor",
                        onClick = { showDetails = !showDetails }
                    )
                    ProjectsItemDesktop(
                        "Note Ninja",
                        "https://play.google.com/store/apps/details?id=com.pockettiger.noteninja&hl=en_US&gl=US",
                        onClick = { showDetails = !showDetails }
                    )
                    ProjectsItemDesktop(
                        "LYNX Flash Cards",
                        "https://play.google.com/store/apps/details?id=com.pockettiger.lynx&pli=1",
                        onClick = { showDetails = !showDetails }
                    )
                    ProjectsItemDesktop(
                        "News Reader Prototype",
                        "https://austenmesaros.com/android/CFPU/app-release.apk",
                        onClick = { showDetails = !showDetails }
                    )
                    ProjectsItemDesktop(
                        "Mouse Maze Generator",
                        "https://austenmesaros.com/demos/MazeWeb/index.html",
                        onClick = { showDetails = !showDetails }
                    )
                    ProjectsItemDesktop("Fishy Remake", "https://austenmesaros.com/demos/FishyRemake/fishy.html",
                        onClick = { showDetails = !showDetails })
                    ProjectsItemDesktop(
                        "Falling Blocks",
                        "https://austenmesaros.com/demos/FallingBlocks/index.html",
                        onClick = { showDetails = !showDetails }
                    )
                    ProjectsItemDesktop("Space Maze", "https://austenmesaros.com/demos/SpaceMaze/index.html",
                        onClick = { showDetails = !showDetails })
                }
            }

            var selected = remember { mutableMapOf(Pair<String, String>("", "")) }

            Box(
                contentAlignment = Alignment.CenterStart,
                modifier = Modifier.fillMaxWidth(50.percent)
                    .fillMaxHeight()
                    .color(Colors.White)
                    .backgroundColor(rgba(255,255,255,0.1f))
                    .borderRadius(7.px)
                    .overflow(Overflow.Auto)
                    .padding(10.px)
                    .gap(10.px)
                    .border(1.px, LineStyle.Solid, Colors.Red)
                    .animation(FadeEffect.toAnimation(duration = 0.5.s))
                    .scale(0)
                    .transition(CSSTransition("scale", 0.5.s))
                    .thenIf(showDetails, Modifier.scale(1))
            ) {
                Column(verticalArrangement = Arrangement.SpaceEvenly, horizontalAlignment = Alignment.CenterHorizontally,
                    modifier = Modifier.fillMaxSize()) {
                    Image("austenicon.png")
                    Div {
                        Text("Project Title")
                    }
                    Div {
                        Text("Project Desc")
                    }
                    Link(text = "Project URL", path = "")
                }
            }
        }

    } else {
        Column(
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.fillMaxWidth(100.percent)
                .fillMaxHeight()
                .color(Colors.White)
                .overflow(Overflow.Auto)
                .padding(10.px)
                .gap(10.px)
                .animation(FadeEffect.toAnimation(duration = 0.5.s))
        ) {
            ProjectsItem(
                "Leftover Monitor",
                "https://play.google.com/store/apps/details?id=com.austenmesaros.leftovermonitor"
            )
            ProjectsItem(
                "Note Ninja",
                "https://play.google.com/store/apps/details?id=com.pockettiger.noteninja&hl=en_US&gl=US"
            )
            ProjectsItem(
                "LYNX Flash Cards",
                "https://play.google.com/store/apps/details?id=com.pockettiger.lynx&pli=1"
            )
            ProjectsItem(
                "News Reader Prototype",
                "https://austenmesaros.com/android/CFPU/app-release.apk"
            )
            ProjectsItem(
                "Mouse Maze Generator",
                "https://austenmesaros.com/demos/MazeWeb/index.html"
            )
            ProjectsItem("Fishy Remake", "https://austenmesaros.com/demos/FishyRemake/fishy.html")
            ProjectsItem(
                "Falling Blocks",
                "https://austenmesaros.com/demos/FallingBlocks/index.html"
            )
            ProjectsItem("Space Maze", "https://austenmesaros.com/demos/SpaceMaze/index.html")
        }
    }
}

@Composable
fun ProjectsItemDesktop(projectTitle: String, path: String, onClick: () -> Unit = {}) {
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth()
            .height(100.px)
            .backgroundColor(rgba(255,255,255,0.1f))
            .borderRadius(7.px)
            .onClick { onClick.invoke() }
    ) {

//        ProjectsIcon()
        ProjectsTitleDesktop(projectTitle, path)
//        ProjectsLinksContainer {}
    }
}

@Composable
fun ProjectsItem(projectTitle: String, path: String) {
    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxWidth()
            .height(100.px)
            .backgroundColor(rgba(255,255,255,0.1f))
            .borderRadius(7.px)
    ) {

//        ProjectsIcon()
        ProjectsTitle(projectTitle, path)
//        ProjectsLinksContainer {}
    }
}

@Composable
fun ProjectsIcon() {
    Box(
        contentAlignment = Alignment.Center,
        modifier = Modifier
            .size(100.px)
            .backgroundColor(Colors.White)
            .borderRadius(7.px)
    ) {
        FaAndroid(size = IconSize.X3, modifier = Modifier.color(Colors.LimeGreen))
    }
}

@Composable
fun ProjectsTitleDesktop(projectTitle: String, path: String) {
    Box(
        contentAlignment = Alignment.Center,
        modifier = ProjectsTitleStyle.toModifier()
    ) {
        Text(projectTitle)
/*        Link(
            path = path,
            text = projectTitle,
            modifier = Modifier.textDecorationLine(TextDecorationLine.None).color(Colors.DeepSkyBlue)
        )*/
    }
}


@Composable
fun ProjectsTitle(projectTitle: String, path: String) {
    Box(
        contentAlignment = Alignment.Center,
        modifier = ProjectsTitleStyle.toModifier()
    ) {
        Link(
            path = path,
            text = projectTitle,
            modifier = Modifier.textDecorationLine(TextDecorationLine.None).color(Colors.DeepSkyBlue)
        )
    }
}

/*@Composable
fun ProjectsLinksContainer(projectLink: @Composable () -> Unit) {
    Link(
        modifier = Modifier
            .textDecorationLine(TextDecorationLine.None)
            .color(Colors.DeepSkyBlue), path = ""
    ) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .borderRadius(7.px)
                .backgroundColor(rgba(255, 255, 255, 1.0f))
                .size(100.px)
        ) {
            FaGooglePlay(size = IconSize.X3, modifier = Modifier.color(Colors.DeepSkyBlue))
        }
    }
}*/

//@Composable
//fun ProjectsLink() {
//    Box (
//        contentAlignment = Alignment.Center,
//        modifier = Modifier
//            .borderRadius(100.percent)
//            .backgroundColor(Colors.White)
//            .size(2.5.vw)
//    ) {
//            FaGooglePlay(size = IconSize.XXL, modifier = Modifier.color(Colors.Black))
//    }
//}

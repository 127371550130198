package com.austen.mesaros.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.animation
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.textDecorationLine
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.s
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul

val EducationHeaderStyle by ComponentStyle {
    base {
        Modifier
            .color(Colors.White)
            .fontSize(2.5.vw)
            .textAlign(TextAlign.Center)
    }
}

val EducationDescStyle by ComponentStyle {
    base {
        Modifier
            .color(Colors.White)
            .fontSize(FontSize.Small)
            .lineHeight(1.5.em)
    }

    Breakpoint.LG {
        Modifier
            .color(Colors.White)
            .fontSize(0.75.vw)
            .lineHeight(1.5.em)
    }
}

@Composable
fun Education() {

    val breakpoint = rememberBreakpoint()
    /*Text(breakpoint.toString())*/

    if (breakpoint == Breakpoint.LG || breakpoint == Breakpoint.XL) {
        Row(
            horizontalArrangement = Arrangement.Start,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxSize()
                .padding(10.px)
                .gap(10.px)
                .animation(FadeEffect.toAnimation(duration = 0.5.s))
                .overflow(Overflow.Auto)
        ) {
            EducationItem("Park University") { ParkUniversityBulletList() }
            EducationItem("Johnson County Community College") { JCCCBulletList() }
            EducationItem("Metropolitan Community College") { MCCBulletList() }
        }
    } else {
        Column(
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .fillMaxSize()
                .padding(10.px)
                .gap(10.px)
                .animation(FadeEffect.toAnimation(duration = 0.5.s))
                .overflow(Overflow.Auto)
        ) {
            EducationItem("Park University") { ParkUniversityBulletList() }
            EducationItem("Johnson County Community College") { JCCCBulletList() }
            EducationItem("Metropolitan Community College") { MCCBulletList() }
        }
    }
}

@Composable
fun EducationItem(universityName: String, content: @Composable () -> Unit) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .borderRadius(topLeft = 7.px, topRight = 7.px)
            .backgroundColor(rgba(255,255,255,0.1f))
    ) {
        Box(contentAlignment = Alignment.Center,
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight(25.percent)
                .backgroundColor(rgba(255,255,255,0.1f))
                .borderRadius(topLeft = 7.px, topRight = 7.px)
        ) {
            Div(
                attrs = EducationHeaderStyle.toModifier()
                    .fontWeight(FontWeight.Bold).toAttrs()
            ) {
                Text(universityName)
            }
        }
        Box(contentAlignment = Alignment.TopStart,
            modifier = EducationDescStyle.toModifier()
                .fillMaxWidth()
                .fillMaxHeight(75.percent)
                .color(Colors.White)
                .overflow(Overflow.Auto)
        ) {
            content()
        }
    }
}

@Composable
private fun ParkUniversityBulletList() {
    Ul(attrs = Modifier.color(Colors.White).toAttrs()) {
        Li {
            Link(
                path = "https://www.parchment.com/u/award/5a58d16dd901d5acffaa896e7242ddb1",
                text = "Bachelor of Science in Information and Computer Science: Software Engineering",
                modifier = Modifier.textDecorationLine(TextDecorationLine.None).color(Colors.DeepSkyBlue)
            )
        }
        Li { Text("Summa Cum Laude") }
        Li {
            Link(
                path = "https://www.park.edu/wp-content/uploads/2019/04/HonorsConvocationAwards2019.pdf#page=6",
                text = "Presidential Scholar (2019)",
                modifier = Modifier.textDecorationLine(TextDecorationLine.None).color(Colors.DeepSkyBlue)
            )
        }
        Li {
            Link(
                path = "https://www.park.edu/wp-content/uploads/2018/04/HonorsConvocation2018.pdf#page=9",
                text = "Information Computer Science Honor (2018)",
                modifier = Modifier.textDecorationLine(TextDecorationLine.None).color(Colors.DeepSkyBlue)
            )
        }
        Li {
            Text("ACM Club Vice President")
        }
        Li {
            Text("Dean's List (Spring 2017 | Fall 2017 | Spring 2018 | Fall 2018)")
        }
    }
}

@Composable
private fun JCCCBulletList() {
    Ul {
        Li { Text("Phi Theta Kappa Honor Society Member") }
        Li { Text("President's List (2011)") }
        Li {
            Text("Part-time Honor Roll (Spring 2012 | Fall 2012 | Fall 2016)")
        }
    }
}

@Composable
private fun MCCBulletList() {
    Ul {
        Li { Text("Project Blue River Rescue Volunteer") }
    }
}
package com.austen.mesaros.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.icons.fa.FaCertificate
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

val AchievementTitleStyle by ComponentStyle {
    base {
        Modifier
            .fontSize(FontSize.Large)
            .textAlign(TextAlign.Center)
            .textDecorationLine(TextDecorationLine.None)
            .fillMaxWidth()
            .minHeight(125.px)
            .backgroundColor(rgba(255, 255, 255, 0.1f))
            .borderRadius(7.px)
    }

    Breakpoint.LG {
        Modifier
            .fontSize(1.5.vw)
            .textAlign(TextAlign.Center)
            .textDecorationLine(TextDecorationLine.None)
    }
}

val AchievementIconStyle by ComponentStyle {
    base {
        Modifier
            .display(DisplayStyle.Block)
            /*.fillMaxSize()*/
    }

    Breakpoint.LG {
        Modifier
            .display(DisplayStyle.Block)
    }
}

val Box1Style by ComponentStyle {
    base {
        Modifier
            .fillMaxHeight()
            .fillMaxWidth(25.percent)
    }

    Breakpoint.LG {
        Modifier
            .fillMaxHeight()
            .fillMaxWidth(10.percent)
        /*                    .border(1.px, LineStyle.Solid, Colors.Blue)*/
    }
}

val Box2Style by ComponentStyle {
    base {
        Modifier
            .fillMaxHeight()
            .fillMaxWidth(75.percent)
            .padding(25.px)
            .color(Colors.White)
            .fontSize(1.em)
        /*                    .border(1.px, LineStyle.Solid, Colors.Blue)*/
    }

    Breakpoint.LG {
        Modifier
            .fillMaxHeight()
            .fillMaxWidth(90.percent)
            .padding(25.px)
            .color(Colors.White)
            .transition(CSSTransition("font-size", 1.s))
            .fontSize(1.em)

        /*                    .border(1.px, LineStyle.Solid, Colors.Blue)*/
    }
}

@Composable
fun Achievements() {
    Column(
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
            .fillMaxSize()
            .color(Colors.White)
            .padding(10.px)
            .fontSize(1.vw)
            .gap(10.px)
            .animation(FadeEffect.toAnimation(duration = 0.5.s))
            .overflow(Overflow.Auto)
    ) {
            AchievementsItem("https://www.parchment.com/u/award/5a58d16dd901d5acffaa896e7242ddb1", "Bachelor of Science in Information and Computer Science: Software Engineering")
            AchievementsItem("https://www.park.edu/wp-content/uploads/2018/04/HonorsConvocation2018.pdf#page=9", "Information Computer Science Department Honor")
            AchievementsItem("https://www.park.edu/wp-content/uploads/2019/04/HonorsConvocationAwards2019.pdf#page=6", "Presidential Scholar")
            AchievementsItem("https://www.udemy.com/certificate/UC-I328T8YN/", "The Comprehensive Android App Development Masterclass")
            AchievementsItem("https://www.udemy.com/certificate/UC-2387a610-8ee7-45b6-9118-d89486e4f6f6/", "The Complete Cyber Security Course: Hackers Exposed!")
            AchievementsItem("https://www.udemy.com/certificate/UC-a938f8d6-46d7-43b3-a1c2-2884eddb2c78/", "Kotlin for Java Developers")
            AchievementsItem("https://www.udemy.com/certificate/UC-eebd3104-4765-4a5c-abfd-73f8ee1526a8/", "The Beginner's Guide to Artificial Intelligence (Unity 2022)")
            AchievementsItem("https://www.udemy.com/certificate/UC-a6791f45-2d77-4889-ac06-1f467c325e65/", "Agile Crash Course: Agile Project Management; Agile Delivery")
            AchievementsItem("https://www.udemy.com/certificate/UC-a57a1024-cae9-41c8-b650-56d1a21373ee/", "Android App Development Masterclass using Kotlin")
            AchievementsItem("https://www.udemy.com/certificate/UC-701672a6-0111-4d69-ad34-cfdb63c9f61a/", "Android Jetpack Compose: The Comprehensive Bootcamp")
            AchievementsItem("https://www.udemy.com/certificate/UC-fcbaff3d-76c5-47a1-a7e4-cb778b23a62a/", "Java 17 Masterclass: Start Coding in 2024")
            AchievementsItem("https://www.udemy.com/certificate/UC-b08e7aa6-2408-4bb2-b789-95f1286acde0/", "The Java Designs Patterns Course")
            AchievementsItem("https://www.udemy.com/certificate/UC-b9c0419d-a71b-4633-b8c1-e6846f27dbbd/", "Git & GitHub Masterclass")
            AchievementsItem("https://www.udemy.com/certificate/UC-df4a77f9-40d8-437f-a585-bfa304f9a16b/", "Pixel Art Master Course - Beginner to Professional/Freelance")
            AchievementsItem("https://www.udemy.com/certificate/UC-d1055da3-05ad-47d3-bf21-65d2b138979e/", "Ultimate Unity Overview (70+ Tools and Features Explained!)")
            AchievementsItem("https://www.udemy.com/certificate/UC-25ced3f3-a643-4856-b45b-0bf459cd06ec/", "The Ultimate Guide to Video Game Optimisation")
    }
}

@Composable
fun AchievementsItem(path: String, text: String) {

    var scaleUpText by remember { mutableStateOf(false) }

    Link(
        path = path,
        modifier = AchievementTitleStyle.toModifier().textDecorationLine(TextDecorationLine.None)
            .onMouseOver { scaleUpText = true }
            .onMouseOut { scaleUpText = false }
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start,
            modifier = Modifier.fillMaxHeight()
        ) {
            Box(
                contentAlignment = Alignment.Center,
                modifier = Box1Style.toModifier()

            ) {
                Div {
                    FaCertificate(
                        size = IconSize.X2,
                        modifier = AchievementIconStyle.toModifier().color(Colors.GoldenRod)
                    )
                }
            }
            Box(
                contentAlignment = Alignment.CenterStart,
                modifier = Box2Style.toModifier().thenIf(scaleUpText, Modifier.fontSize(1.1.em))
            ) {
                Text(text)
            }
        }
    }
}
package com.austen.mesaros.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.BackgroundPosition
import com.varabyte.kobweb.compose.css.BackgroundSize
import com.varabyte.kobweb.compose.css.CSSAnimation
import com.varabyte.kobweb.compose.css.CSSBackground
import com.varabyte.kobweb.compose.css.CSSPosition
import com.varabyte.kobweb.compose.css.CSSTextShadow
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.css.functions.toImage
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.animation
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.backgroundImage
import com.varabyte.kobweb.compose.ui.modifiers.backgroundPosition
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderLeft
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.borderRight
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.display
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.overflowY
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.textShadow
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.fa.FaGraduationCap
import com.varabyte.kobweb.silk.components.icons.fa.FaHouse
import com.varabyte.kobweb.silk.components.icons.fa.FaMedal
import com.varabyte.kobweb.silk.components.icons.fa.FaSuitcase
import com.varabyte.kobweb.silk.components.icons.fa.FaTerminal
import com.varabyte.kobweb.silk.components.icons.fa.FaWrench
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.common.ariaDisabled
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.not
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.s
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Iframe
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

enum class Section {
    Introduction,
    Skills,
    Projects,
    Experience,
    Education,
    Achievements
}

// ====================================================================================================
// Home Page Style
// ====================================================================================================
val HomePageStyle by ComponentStyle {
    base {
        Modifier
            .margin(0.px)
            .border(0.px)
            .fontFamily("Roboto")
            .fontWeight(FontWeight.Light)
            .fillMaxWidth(100.percent)
            .height(100.vh)
            .maxHeight(100.vh)
            .overflowY(Overflow.Hidden)
/*            .padding(bottom = 55.px)*/
    }

    Breakpoint.LG {
       Modifier
           .margin(topBottom = 0.percent, leftRight = 10.percent)
           .borderLeft(1.px, LineStyle.Solid, Colors.DarkGray)
           .borderRight(1.px, LineStyle.Solid, Colors.DarkGray)
           .fontFamily("Roboto")
           .fontWeight(FontWeight.Light)
           .fillMaxWidth(80.percent)
           .height(100.vh)
           .maxHeight(100.vh)
           .overflowY(Overflow.Hidden)
           .padding(bottom = 0.px)
    }
}

val HeaderStyle by ComponentStyle {
    base {
        Modifier
            .fillMaxWidth()
            .minHeight(100.px)

    }

    Breakpoint.LG {
        Modifier
            .fillMaxWidth()
            .minHeight(200.px)
    }
}

val NavStyle by ComponentStyle {
    base {
        Modifier
            .backgroundColor(Color.rgba(33,33,33,1.0f))
            .fillMaxWidth()
            .minHeight(75.px)
    }

    Breakpoint.LG {
        Modifier
            .backgroundColor(Color.rgba(33,33,33,1.0f))
            .fillMaxWidth()
            .minHeight(75.px)
    }
}

val ContentStyle by ComponentStyle {
    base {
        Modifier
            .height(100.percent - 175.px)
            .maxHeight(100.percent - 175.px)
            .fillMaxWidth()
            .backgroundImage(linearGradient(0.deg, Color.rgba(25, 25, 25, 1.0f), Color.rgba(50, 50, 50, 1.0f)))
    }

    Breakpoint.LG {
        Modifier
            .height(100.percent - 275.px)
            .maxHeight(100.percent - 275.px)
            .fillMaxWidth()
            .backgroundImage(linearGradient(0.deg, Color.rgba(25, 25, 25, 1.0f), Color.rgba(50, 50, 50, 1.0f)))
    }
}

// ====================================================================================================
// Austen Text Style
// ====================================================================================================
val AustenTextStyle by ComponentStyle {
    base {
        Modifier
            .position(Position.Absolute)
            .color(Colors.White)
            .fontFamily("Roboto Mono", "Sans-Serif")
            .textAlign(TextAlign.Center)
            .fontSize(7.vw)
            .textShadow(CSSTextShadow(2.px, 2.px, 4.px, Colors.Black))
            .fontWeight(FontWeight.SemiBold)
    }

    Breakpoint.SM {
        Modifier
//            .fontSize(24.vw)
    }
    Breakpoint.MD {
        Modifier
//            .fontSize(24.vw)
    }

    Breakpoint.LG {
        Modifier
//            .fontSize(24.vw)
    }
}

// ====================================================================================================
// Nav Icon Style
// ====================================================================================================
val navIconStyle by ComponentStyle {
    base {
        Modifier
            .display(DisplayStyle.Block)
            .fontSize(4.vw)
    }

    Breakpoint.LG {
        Modifier
            .display(DisplayStyle.None)
    }
}

val navTextStyle by ComponentStyle {
    base {
        Modifier
            .display(DisplayStyle.None)
    }

    Breakpoint.LG {
        Modifier
            .display(DisplayStyle.Block)
    }
}

// ====================================================================================================
// Body Style
// ====================================================================================================
val BodyStyle by ComponentStyle.base {
    Modifier
        .background(
            CSSBackground(
                image = linearGradient((-45).deg) {
                    add(Color.rgb(0xee7752))
                    add(Color.rgb(0xe73c7e))
                    add(Color.rgb(0x23a6d5))
                    add(Color.rgb(0x23d5ab))
                }.toImage(),
                size = BackgroundSize.of(400.percent, 400.percent)
            )
        )
        .animation(
            Rainbow.toAnimation(
                colorMode,
                duration = 15.s,
                timingFunction = AnimationTimingFunction.Ease,
                iterationCount = AnimationIterationCount.Infinite
            )
        )
        .height(100.vh)
}
val Rainbow by Keyframes {
    0.percent {
        Modifier
            .backgroundPosition(BackgroundPosition.of(CSSPosition(0.percent, 50.percent)))
    }
    50.percent {
        Modifier
            .backgroundPosition(BackgroundPosition.of(CSSPosition(100.percent, 50.percent)))
    }
    100.percent {
        Modifier
            .backgroundPosition(BackgroundPosition.of(CSSPosition(0.percent, 50.percent)))
    }
}

// ====================================================================================================
// Normal Button Style
// ====================================================================================================
val NormalButtonStyle by ComponentStyle {
    base {
        Modifier
            .fillMaxSize()
            .fontFamily("Roboto Condensed")
            .fontWeight(FontWeight.Light)
            .fontSize(1.vw)
            .backgroundColor(Color.rgba(33,33,33,1.0f))
            .color(Colors.White)
            .borderRadius(0.px)
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .animation(CSSAnimation(name = "button-fade-effect", duration = 0.25.s, timingFunction = AnimationTimingFunction.EaseInOut))
            .backgroundColor(Color.rgba(50,50,50,1.0f))
            .cursor(Cursor.Pointer)
    }
}

// ====================================================================================================
// Play Button Style
// ====================================================================================================
val PlayButtonStyle by ComponentStyle {
    base {
        Modifier
            .backgroundColor(Color.rgba(33,33,33,1.0f))
            .color(Colors.White)
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .animation(CSSAnimation(name = "button-fade-effect", duration = 0.25.s, timingFunction = AnimationTimingFunction.EaseInOut))
            .backgroundColor(Color.rgba(50,50,50,1.0f))
            .cursor(Cursor.Pointer)
    }
}

// ====================================================================================================
// Animations
// ====================================================================================================
val FadeEffect by Keyframes {
    from { Modifier.opacity(0.percent) }
    to { Modifier.opacity(100.percent) }
}

val ButtonFadeEffect by Keyframes {
    from { Modifier.backgroundColor(Color.rgba(33,33,33,1.0f)) }
    to { Modifier.backgroundColor(Color.rgba(50,50,50,1.0f)) }
}

val SelectedButtonStyle by ComponentStyle {
    base {
        Modifier
            .fillMaxSize()
            .fontFamily("Roboto Condensed")
            .fontWeight(FontWeight.Light)
            .fontSize(1.vw)
            .backgroundColor(Color.rgba(50,50,50,1.0f))
            .color(Colors.White)
            .borderRadius(0.px)
    }

    (hover + not(ariaDisabled)) {
        Modifier
            .backgroundColor(Color.rgba(50,50,50,1.0f))
            .cursor(Cursor.Pointer)
    }
}


// ========================================================================================================================================================================================================
// Home Page
// ========================================================================================================================================================================================================
@Page
@Composable
fun HomePage() {

    val selectedSection = remember { mutableStateOf(Section.Introduction) }

    Surface(
        modifier = Modifier
            .fillMaxSize()
            .backgroundImage(url("black_lozenge.png"))
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = HomePageStyle.toModifier()
        ) {
            // --------------------------------------------------
            // HEADER
            // --------------------------------------------------
            Row(
                horizontalArrangement = Arrangement.Center,
                verticalAlignment = Alignment.CenterVertically,
                modifier = HeaderStyle.toModifier()

            ) {
                HeaderContent()
            }
            // --------------------------------------------------
            // NAV BAR
            // --------------------------------------------------
            Row(
                modifier = NavStyle.toModifier()
            ) {
                NavBarContent(currentSection = selectedSection.value, onChangeSection = {
                    s -> selectedSection.value = s
                })
            }
            // --------------------------------------------------
            // MAIN CONTENT
            // --------------------------------------------------
             Row (
                 modifier = ContentStyle.toModifier()
             ) {
                when (selectedSection.value) {
                    Section.Introduction -> Introduction()
                    Section.Skills -> Skills()
                    Section.Projects -> Projects()
                    Section.Experience -> Experience()
                    Section.Education -> Education()
                    Section.Achievements -> Achievements()
                }
            }
        }
    }
}

// ====================================================================================================
// Header Content
// ====================================================================================================
@Composable
private fun HeaderContent() {
    Box(
        contentAlignment = Alignment.Center,
        modifier = Modifier
            .fillMaxSize()
            .background(
                CSSBackground(
                    image = linearGradient(270.deg) {
                        add(Color.rgb(0x76b852), 0.percent)
/*                        add(Color.rgb(0xC779D0), 50.percent)*/
                        add(Color.rgb(0x8DC26F), 100.percent)
                    }.toImage()
                )
            )
            .overflow(Overflow.Hidden)
    ) {

        val unityGameVisible = remember { mutableStateOf(false) }

        if (unityGameVisible.value) {
            Iframe(
                attrs = {
                    attr("width", "100%")
                    attr("height", "100%")
                    attr("frameBorder", "0")
                    attr("display", "block")
                    attr("scrolling", "no")
                    attr("src", "WebsiteTest/index.html")
                    attr("seamless", "")
                }
            )
        } else {
            Row(horizontalArrangement = Arrangement.Center, verticalAlignment = Alignment.CenterVertically, modifier = Modifier.fillMaxSize()/*.border(1.px, LineStyle.Solid, Colors.Red)*/) {

                Div(attrs = AustenTextStyle.toAttrs()) {
                    Text("Austen Mesaros".uppercase())
                }

/*                Box(contentAlignment = Alignment.Center, modifier = Modifier.fillMaxWidth(47.5.percent).fillMaxHeight()*//*.border(1.px, LineStyle.Solid, Colors.Red)*//*) {
                    Div(attrs = AustenTextStyle.toAttrs()) {
                        Text("Austen".uppercase())
                    }
                }
                Box(contentAlignment = Alignment.Center, modifier = Modifier.fillMaxWidth(5.percent).fillMaxHeight()*//*.border(1.px, LineStyle.Solid, Colors.Green)*//*) {
                    Button(onClick = { unityGameVisible.value = true }, size = ButtonSize.LG, modifier = PlayButtonStyle.toModifier()) { FaPlay(size = IconSize.LG, modifier = Modifier.color(Colors.White)) }
                }
                Box(contentAlignment = Alignment.Center, modifier = Modifier.fillMaxWidth(47.5.percent).fillMaxHeight()*//*.border(1.px, LineStyle.Solid, Colors.Blue)*//*) {
                    Div(attrs = AustenTextStyle.toAttrs()) {
                        Text("Mesaros".uppercase())
                    }
                }*/
//                Div(attrs = AustenTextStyle.toAttrs()) {
//                    Text("Mesaros".uppercase())
//                }
            }
        }

        // --------------------------------------------------
        // External Links Container (Mail, etc..)
        // --------------------------------------------------
//        Box(
//            contentAlignment = Alignment.CenterEnd,
//            modifier = Modifier
//                .fillMaxSize()
//        ) {
//            Column(
//                verticalArrangement = Arrangement.Bottom,
//                horizontalAlignment = Alignment.CenterHorizontally,
//                modifier = Modifier
//                    .fillMaxHeight()
//                    .padding(10.px)
//            ) {
//                Link(path = "mailto:austen.mesaros@outlook.com", modifier = Modifier.position(Position.Relative).textDecorationLine(TextDecorationLine.None).color(Colors.Transparent)) {
//                    Box(
//                        contentAlignment = Alignment.Center,
//                        modifier = Modifier
//                            .backgroundColor(Colors.White)
//                            .borderRadius(100.percent)
//                    ) {
//                        FaEnvelope(size = IconSize.X2, modifier = Modifier.padding(10.px).color(Colors.Black))
//                    }
//                }
//            }
//        }
    }
}

// ====================================================================================================
// Nav Bar Content
// ====================================================================================================
@Composable
private fun NavBarContent(currentSection: Section, onChangeSection: (Section) -> Unit) {
    Row(
        horizontalArrangement = Arrangement.SpaceEvenly,
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier
            .fillMaxSize()
            .color(Colors.White)
            .padding(leftRight = 1.px)
    )
    {
        Button(onClick = { onChangeSection(Section.Introduction) }, modifier = if(currentSection == Section.Introduction) SelectedButtonStyle.toModifier() else NormalButtonStyle.toModifier()) {
            Span(attrs = navIconStyle.toAttrs()) { FaHouse() }
            Span(attrs = navTextStyle.toAttrs()) { Text("Introduction".uppercase()) }
        }
        Button(onClick = { onChangeSection(Section.Skills) }, modifier = if(currentSection == Section.Skills) SelectedButtonStyle.toModifier() else NormalButtonStyle.toModifier()) {
            Span(attrs = navIconStyle.toAttrs()) { FaWrench() }
            Span(attrs = navTextStyle.toAttrs()) { Text("Skills".uppercase()) }
        }
        Button(onClick = { onChangeSection(Section.Projects) }, modifier = if(currentSection == Section.Projects) SelectedButtonStyle.toModifier() else NormalButtonStyle.toModifier()) {
            Span(attrs = navIconStyle.toAttrs()) { FaTerminal() }
            Span(attrs = navTextStyle.toAttrs()) { Text("Projects".uppercase()) }
        }
        Button(onClick = { onChangeSection(Section.Experience) }, modifier = if(currentSection == Section.Experience) SelectedButtonStyle.toModifier() else NormalButtonStyle.toModifier()) {
            Span(attrs = navIconStyle.toAttrs()) { FaSuitcase() }
            Span(attrs = navTextStyle.toAttrs()) { Text("Experience".uppercase()) }
        }
        Button(onClick = { onChangeSection(Section.Education) }, modifier = if(currentSection == Section.Education) SelectedButtonStyle.toModifier() else NormalButtonStyle.toModifier() ) {
            Span(attrs = navIconStyle.toAttrs()) { FaGraduationCap() }
            Span(attrs = navTextStyle.toAttrs()) { Text("Education".uppercase()) }
        }
        Button(onClick = { onChangeSection(Section.Achievements) }, modifier = if(currentSection == Section.Achievements) SelectedButtonStyle.toModifier() else NormalButtonStyle.toModifier()) {
            Span(attrs = navIconStyle.toAttrs()) { FaMedal() }
            Span(attrs = navTextStyle.toAttrs()) { Text("Achievements".uppercase()) }
        }
    }
}
// ========================================================================================================================================================================================================
// END HOME PAGE
// ========================================================================================================================================================================================================
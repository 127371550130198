package com.austen.mesaros.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontSize
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.icons.fa.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import org.jetbrains.compose.web.dom.Text


class Job(val companyName: String, val jobTitle: String, val location: String, val yearsActive:String, val bulletDescriptionList: List<String>)

val JobHeaderStyle by ComponentStyle {
    base {
        Modifier
            .color(Colors.White)
            .fontSize(FontSize.Medium)
            .textAlign(TextAlign.Center)
    }

    Breakpoint.LG {
        Modifier
            .color(Colors.White)
            .fontSize(1.25.vw)
            .textAlign(TextAlign.Center)
    }
}

val JobLocationActiveStyle by ComponentStyle {
    base {
        Modifier
            .color(Colors.White)
            .fontSize(FontSize.XSmall)
            .textAlign(TextAlign.Left)
    }

    Breakpoint.LG {
        Modifier
            .color(Colors.White)
            .fontSize(0.75.vw)
            .textAlign(TextAlign.Left)
    }
}

val JobDescStyle by ComponentStyle {
    base {
        Modifier
            .color(Colors.White)
            .fontSize(FontSize.Small)
            .lineHeight(1.5.em)
    }

    Breakpoint.LG {
        Modifier
            .color(Colors.White)
            .fontSize(0.75.vw)
            .lineHeight(1.5.em)
    }
}

@Composable
fun Experience() {
    val job1 = Job("Freelance", "Android Developer", "Overland Park, KS", "Dec 2021 - Current",
        listOf("Publishing apps and games under the \"Austen Mesaros\" and \"Pocket Tiger\" banners",
                "Focusing on modern Android design methodologies, including Compose and Material Design",
                "Emphasis on creating practical apps that are easy to use and customize",
            "Writing code in a modular and consistent way to make apps easy to maintain and update"
        )
    )
    val job2 = Job("Farmers Insurance", "Help Desk Analyst I", "Oklahoma City, OK", "Feb 2020 - Dec 2021",
        listOf(
            "Helping Farmers employees resolve IT issues",
            "Utilizing personal experience and knowledge bases to diagnose and solve problems quickly",
            "Collaborating with team members to uncover new solutions",
            "Keeping Farmers Insurance up-to-date in IT")
    )
    val job3 = Job("Park University", "Professional Tutor", "Parkville, MO", "Oct 2019 - Jan 2020",
        listOf(
            "Helping tutees achieve their learning goals via quality instruction",
            "Focusing on the individual needs of each student and adapting accordingly",
            "Assisting tutees with overcoming hurdles in their educational journey",
            "Sharpening skills as software engineer and communicator")
    )
    val job4 = Job("Park University", "Programmer", "Parkville, MO", "May 2018 - Aug 2018",
        listOf(
            "Designing creative challenges for students based on concepts taught in class textbook",
            "Writing homework description documents and grading rubrics",
            "Developing the solution code for 20+ Java programs",
            "Adhering to specific coding conventions to meet Park University quality standards")
    )

    Column(
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally,
        modifier = Modifier
            .fillMaxSize()
            .padding(10.px)
            .gap(10.px)
            .animation(FadeEffect.toAnimation(duration = 0.5.s))
            .overflow(Overflow.Auto)
    ) {
        ExperienceItem(job1)
        ExperienceItem(job2)
        ExperienceItem(job3)
        ExperienceItem(job4)
    }
}

@Composable
fun ExperienceItem(job: Job) {

    Column(
        modifier = Modifier
        .fillMaxSize()
        .borderRadius(topLeft = 7.px, topRight = 7.px)
        .backgroundColor(rgba(255,255,255,0.1f))
    ) {
        Row(horizontalArrangement = Arrangement.SpaceEvenly,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight(25.percent)
                .backgroundColor(rgba(255,255,255,0.1f))
                .borderRadius(topLeft = 7.px, topRight = 7.px)
        ) {
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier.fillMaxSize()
            ) {
                Div(
                    attrs = JobHeaderStyle.toModifier()
                        .fontWeight(FontWeight.Bold).toAttrs()
                ) {
                    Text(job.companyName)
                }
            }
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier.fillMaxSize()
                    .borderLeft(1.px, LineStyle.Solid, Colors.DarkGray)
                    .borderRight(1.px, LineStyle.Solid, Colors.DarkGray)
            ) {
                Div(
                    attrs = JobHeaderStyle.toAttrs()) {
                    Text(
                        job.jobTitle
                    )
                }
            }
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier.fillMaxSize()
            ) {
                Column {
                    Div(
                        attrs = JobLocationActiveStyle.toModifier()
                            .color(Colors.DarkGray).toAttrs()
                    ) {
                        Text(job.location);

                    }
                    Div(
                        attrs = JobLocationActiveStyle.toModifier()
                            .color(Colors.DarkGray).toAttrs()
                    ) {
                        Text(job.yearsActive)

                    }
                }
            }
//            Box(
//                contentAlignment = Alignment.Center,
//                modifier = Modifier.fillMaxSize()
//                    .border(1.px, LineStyle.Solid, Colors.Orange)
//                    .onClick { expanded.value = !expanded.value }
//            ) {
//                if (expanded.value) FaChevronDown(size = IconSize.XXL) else FaChevronUp(size = IconSize.XXL)
//            }
        }
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start,
            modifier = Modifier
                .fillMaxWidth()
                .fillMaxHeight(75.percent)
                .padding(10.px)
                .overflow(Overflow.Hidden)
        ) {
            Ul {
                job.bulletDescriptionList.forEach {
                    Li(attrs = JobDescStyle.toAttrs()) {
                        Text(it)
                    }
                }
            }
        }
    }
}


//@Composable
//fun ExperienceItem(job: Job) {
//
//    Column(modifier = Modifier
//        .fillMaxSize()
//        .borderRadius(7.px)
//        .backgroundColor(rgba(255,255,255,0.1f))
//    ) {
//        Row(
//            modifier = Modifier
//                .fillMaxWidth()
//                .fillMaxHeight()
//                .backgroundColor(rgba(255,255,255,0.1f))
//                .borderRadius(topLeft = 7.px, bottomLeft = 7.px)
//        ) {
//            Row(modifier = Modifier
//                .fillMaxSize()
//            ) {
//                Column(
//                    verticalArrangement = Arrangement.Bottom,
//                    horizontalAlignment = Alignment.CenterHorizontally,
//                    modifier = Modifier.fillMaxSize()
//                ) {
//                    Div(attrs = Modifier.fontSize(1.25.vw).fontWeight(FontWeight.Bold).toAttrs()) { Text(job.companyName) }
//                    Div(attrs = Modifier.fontSize(1.25.vw).toAttrs()) { Text(job.jobTitle) }
//
//                }
//            }
//            Row(modifier = Modifier
//                .fillMaxSize()
//            ) {
//                Column(
//                    verticalArrangement = Arrangement.Center,
//                    horizontalAlignment = Alignment.CenterHorizontally,
//                    modifier = Modifier
//                        .fillMaxSize()
//                ){
//                    Div(attrs = Modifier.fontSize(0.75.vw).color(Colors.DarkGray).toAttrs()) { Text(job.location) }
//                    Div(attrs = Modifier.fontSize(0.75.vw).color(Colors.DarkGray).toAttrs()) { Text(job.yearsActive) }
//                }
//            }
//        }
//        Row(
//            verticalAlignment = Alignment.CenterVertically,
//            horizontalArrangement = Arrangement.Start,
//            modifier = Modifier
//                .fillMaxWidth(75.percent)
//                .fillMaxHeight()
//                .padding(10.px)
//        ) {
//            Ul {
//                job.bulletDescriptionList.forEach {
//                    Li(attrs = JobDescStyle.toAttrs()) {
//                        Text(it)
//                    }
//                }
//            }
//        }
//    }
//}
package com.austen.mesaros.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toAttrs
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

val IntroColumnAStyle by ComponentStyle {
    base {
        Modifier
            .color(Colors.White)
            .padding(25.px)
    }

    Breakpoint.LG {
        Modifier
            .color(Colors.White)
            .fontSize(0.9.vw)
            .padding(50.px)
    }
}

val IntroColumnBStyle by ComponentStyle {
    base {
        Modifier
            .display(DisplayStyle.None)
    }

    Breakpoint.LG {
        Modifier
            .display(DisplayStyle.Inline)
            .backgroundColor(Colors.Transparent)
            .fillMaxHeight()
    }
}

@Composable
fun Introduction() {

    Row(modifier = Modifier
        .animation(FadeEffect.toAnimation(duration = 0.5.s))
        .fillMaxSize()
    ) {
        // Column A
        Column(
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier
                .fillMaxSize()
                .overflowY(Overflow.Auto)
        ) {
            Span(attrs = IntroColumnAStyle.toModifier().toAttrs()
            ) {
                P(attrs = Modifier.lineHeight(1.5.em).toAttrs()) {
                    Text("Welcome to my website. I've created this page to quickly share more about myself and my endeavors.")
                }
                P(attrs = Modifier.lineHeight(1.5.em).toAttrs()) {
                    Text(
                        value = "Career-wise I'm a software developer, although I prefer to think of myself as something of a renaissance man; I love trying new things and strive to have many areas of knowledge. " +
                                "I find this mentality especially helpful in the world of computing, which is vast and constantly changing. " +
                                "For this reason I continue my studies online, pursue certifications, associate with tech-forward groups, and challenge myself to create."
                    )
                }
                P(attrs = Modifier.lineHeight(1.5.em).toAttrs()) {
                    Text(
                        value = "It's been very interesting to see how software has evolved over the years, and I'm greatly looking forward to its future. " +
                                "I especially love the growing emphasis on simplicity in how we interact with the many new tools at our disposal. " +
                                "It's my personal goal to create software that's intuitive and practical so users can more easily achieve their own objectives without unnecessary learning curves. " +
                                "I seek to accomplish this by keeping up with best coding and UI/UX practices, as well as designing things I would personally use."
                    )
                }
                P(attrs = Modifier.lineHeight(1.5.em).toAttrs()) {
                    Text(
                        value = "The art of game development is also a longtime interest of mine, which I often credit as the catalyst for my enthusiasm for all forms of interactive media. " +
                                "It is a powerful ability to simulate stories and scenarios that challenge our way of thinking, but to do so in a way that is so visual and dynamic is nothing short of incredible. " +
                                "The entertainment and social components are just icing on the cake. I'm thrilled that game development is more accessible than ever, although truly there are few fields with such depth and reward."
                    )
                }
                P(attrs = Modifier.lineHeight(1.5.em).toAttrs()) {
                    Text(
                        value = "My software interests aside, I'm a free thinker who's motivated by truth and knowledge, especially as it relates to being one's best self. " +
                                "For me this includes leading by example, helping others, and never giving up. " +
                                "Some of my hobbies are photography, gaming, exercise, and learning a second language. " +
                                "I'm currently located in Overland Park, Kansas, where I continue to work on projects and explore new ideas. " +
                                "Check out the other tabs on my website for a brief overview of my experience, or if you would like to get in touch with me I can be reached at: "
                    )
                    Link(
                        path = "mailto:austen.mesaros@outlook.com",
                        text = "austen.mesaros@outlook.com",
                        modifier = Modifier.color(Colors.DeepSkyBlue).textDecorationLine(TextDecorationLine.None)
                    )
                }
            }
        }
        // Column B
        Column(
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.End,
            modifier = IntroColumnBStyle.toModifier()
        ) {
            Image(
                src = "austen.jpg",
                modifier = Modifier.fillMaxHeight()
            )
        }
    }
}